import { Link, navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { companyNavHolder } from './CompanyNav.module.scss';
import { AnimatePresence, motion } from 'framer-motion';
import Corners from './Corners';
import { clamp, inverseLerp, easeInCubic, easeOutCubic, arrayRotate } from '../utils/utils';

const holderVariants = {
  initial: {
    opacity: 0,
    x: '100%',
  },
  animate: {
    opacity: 1,
    x: '0%',
    transition: {
      duration: 0.3,
    },
  },
  isNavigating: {
    opacity: 0.4,
    x: '0%',
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    x: '100%',
    transition: {
      duration: 0.4,
    },
  },
};
const liVariants = {
  initial: {
    scaleY: 1,
    scaleX: 1,
    opacity: 0,
  },

  animate: ({ index, companiesLength }) => {
    const offset = Math.floor(companiesLength / 2) - index;
    const top = Math.floor(companiesLength / 4);
    const sign = Math.sign(offset);

    //calculate the opacity
    let opacity = clamp(Math.abs(offset), 0, Math.abs(top));
    opacity = inverseLerp(top, 0, opacity);
    opacity = easeOutCubic(opacity);

    //calculate the scale
    let scaleY = clamp(Math.abs(offset), 0, Math.abs(top));
    scaleY = inverseLerp(top, 0, scaleY + 0.1);
    scaleY = easeOutCubic(scaleY);

    //calculate the translate
    let y = clamp(Math.abs(offset), 0, Math.abs(top));
    y = inverseLerp(0, Math.abs(top), y);
    y = easeInCubic(y) * 30 * sign;

    let x = 0;

    return {
      opacity: opacity,
      scaleY: scaleY,
      scaleX: 1,
      y: y,
      x: x,
      fontWeight: offset === 0 ? 500 : 300,
      transition: {
        duration: 0.3,
        type: 'tween',
      },
    };
  },
};

const getIndexesOfMatchingCompanies = (wheelItems, slug) => {
  return wheelItems.map((company, index) => {
    if (company.slug === slug) {
      return index;
    }
    return null;
  });
};

const getLowestShiftAmount = (activeCompanyIndex, incomingCompanyIndexes) => {
  let lowestShiftAmount = null;
  incomingCompanyIndexes.forEach((incomingCompanyIndex) => {
    const shiftAmount = incomingCompanyIndex - activeCompanyIndex;
    if (lowestShiftAmount === null || Math.abs(shiftAmount) < Math.abs(lowestShiftAmount)) {
      lowestShiftAmount = shiftAmount;
    }
  });
  return lowestShiftAmount;
};

const reorderWheel = (setWheel, pageContext) => {
  const { companies: allCompanies, slug } = pageContext;

  // console.log(allCompanies);
  setWheel((wheelItems) => {
    if (wheelItems.length === 0) {
      // set up wheel items
      const incomingCompanyIndex = allCompanies.findIndex((company) => company.slug === slug);
      const incomingCompany = allCompanies[incomingCompanyIndex];
      let wheelWithIncomingCompanyAtCentre = [
        ...allCompanies.slice(incomingCompanyIndex),
        ...allCompanies,
        ...allCompanies.slice(0, incomingCompanyIndex),
      ];
      wheelWithIncomingCompanyAtCentre = wheelWithIncomingCompanyAtCentre.map((company, index) => {
        return {
          initialIndex: index,
          active: company.id === incomingCompany.id,
          ...company,
        };
      });
      wheelWithIncomingCompanyAtCentre[0].active = false;
      return wheelWithIncomingCompanyAtCentre;
    } else {
      // wheel is already set up
      // find current index
      const activeCompanyIndex = wheelItems.findIndex((company) => company.active === true);
      const incomingCompanyIndexes = getIndexesOfMatchingCompanies(wheelItems, slug);

      // work out the shift amount
      const shiftAmount = getLowestShiftAmount(activeCompanyIndex, incomingCompanyIndexes);

      let shiftedCompanies = arrayRotate([...wheelItems], shiftAmount);

      shiftedCompanies = shiftedCompanies.map((company, index) => {
        return {
          ...company,
          active: index === activeCompanyIndex ? true : false,
        };
      });

      // get highest initialIndex from shiftedCompanies
      const highestInitialIndex = shiftedCompanies.reduce((acc, company) => {
        if (company.initialIndex > acc) {
          return company.initialIndex;
        }
        return acc;
      }, 0);

      // for each company in shiftedCompanies, if there is a very large shift
      // (i.e. if it's going from top to bottom or bottom to top)
      // we give it a new initialIndex to stop it from jumping
      shiftedCompanies = shiftedCompanies.map((company, index) => {
        const shift = index - wheelItems.findIndex((c) => c.initialIndex === company.initialIndex);

        return {
          ...company,
          initialIndex: Math.abs(shiftAmount) === Math.abs(shift) ? company.initialIndex : highestInitialIndex + 1 + index,
        };
      });

      return shiftedCompanies;
    }
  });
};

export default function CompanyNav({ pageContext, isNavigating, setIsNavigating }) {
  const [wheel, setWheel] = useState([]);
  const [panInfo, setPanInfo] = useState(null);
  const [autoRotate, setAutoRotate] = useState(true);
  const timer = useRef(null);

  const activeIndex = wheel.findIndex((company) => company.active === true);

  useEffect(() => {
    reorderWheel(setWheel, pageContext);
  }, [pageContext]);

  useEffect(() => {
    if (autoRotate) {
      timer.current = setTimeout(() => {
        navigate(`/who-we-are/${wheel[activeIndex + 1].slug}`);
      }, 5000);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [wheel, autoRotate, activeIndex]);

  return (
    <div className={companyNavHolder}>
      <motion.nav
        variants={holderVariants}
        key="companyNav"
        initial="initial"
        animate={isNavigating ? 'isNavigating' : 'animate'}
        exit="exit"
        style={{ '--numberOfItems': pageContext.companies.length, touchAction: 'none' }}
        onWheel={(e) => {
          if (!isNavigating) {
            const nextCompany = e.deltaY > 0 ? wheel[activeIndex + 1] : wheel[activeIndex - 1];
            setAutoRotate(false);
            navigate(`/who-we-are/${nextCompany.slug}`);
          }
          setIsNavigating(true);
        }}
        onPanStart={(e, info) => {
          if (!isNavigating) setPanInfo(info);
        }}
        onPanEnd={(e, info) => {
          if (!isNavigating) {
            const nextCompany = info.offset.y < panInfo.offset.y ? wheel[activeIndex + 1] : wheel[activeIndex - 1];
            if (nextCompany) {
              setAutoRotate(false);
              navigate(`/who-we-are/${nextCompany.slug}`);
            }
            setPanInfo(null);
          }
        }}>
        <motion.ul>
          {wheel?.length > 0 &&
            wheel.map((company, index) => (
              <motion.li
                variants={liVariants}
                key={`${company.id}-${company.initialIndex}`}
                layout
                initial="initial"
                custom={{ index, companiesLength: wheel.length }}
                transition={{ duration: 0.5, type: 'tween' }}
                animate="animate"
                style={{ originX: 1, originY: 0.5 }}>
                <Link
                  to={`/who-we-are/${company.slug}`}
                  onClick={(e) => {
                    if (isNavigating) e.preventDefault();
                    setAutoRotate(false);
                  }}>
                  {company.title}
                  <AnimatePresence>{company.slug === pageContext.slug && <Corners />}</AnimatePresence>
                </Link>
              </motion.li>
            ))}
        </motion.ul>
      </motion.nav>

      <button onClick={() => setAutoRotate((autoRotate) => !autoRotate)}>
        {autoRotate ? (
          <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 13V0H3V13H0Z" fill="currentColor" />
            <path d="M7 13V0H10V13H7Z" fill="currentColor" />
          </svg>
        ) : (
          <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 12V2.03125V1L9 6.5L1 12Z" fill="currentColor" stroke="currentColor" />
          </svg>
        )}
      </button>
    </div>
  );
}
