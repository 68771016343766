import React from 'react';

import { AnimatePresence, motion } from 'framer-motion';

const cornersVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const topLeftVariants = {
  initial: {
    x: 0,
    y: 0,
  },
  animate: {
    x: '-100%',
    y: '-20%',
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'anticipate',
    },
  },
  exit: {
    x: 0,
    y: 0,
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'anticipate',
    },
  },
};

const topRightVariants = {
  initial: {
    x: 0,
    y: 0,
  },
  animate: {
    x: '100%',
    y: '-20%',
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'anticipate',
    },
  },
  exit: {
    x: 0,
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'anticipate',
    },
  },
};

const bottomLeftVariants = {
  initial: {
    x: 0,
    y: 0,
  },
  animate: {
    x: '-100%',
    y: '20%',
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'anticipate',
    },
  },
  exit: {
    x: 0,
    y: 0,
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'anticipate',
    },
  },
};

const bottomRightVariants = {
  initial: {
    x: 0,
    y: 0,
  },
  animate: {
    x: '100%',
    y: '20%',
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'anticipate',
    },
  },
  exit: {
    x: 0,
    y: 0,
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'anticipate',
    },
  },
};
export default function Corners() {
  return (
    <motion.div className="corners" variants={cornersVariants} initial="initial" animate="animate" exit="exit">
      <motion.div className="corner top-left" variants={topLeftVariants}>
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 1L1 1L0.999999 11" stroke="black" />
        </svg>
      </motion.div>
      <motion.div className="corner top-right" variants={topRightVariants}>
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 11L10 0.999999L-1.88774e-07 1" stroke="black" />
        </svg>
      </motion.div>
      <motion.div className="corner bottom-left" variants={bottomLeftVariants}>
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 0L1 10H11" stroke="black" />
        </svg>
      </motion.div>
      <motion.div className="corner bottom-right" variants={bottomRightVariants}>
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 10L10 10L10 -6.15928e-07" stroke="black" />
        </svg>
      </motion.div>
    </motion.div>
  );
}
