import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { navHolder, burgerHolder } from './Nav.module.scss';
import { motion } from 'framer-motion';
import '../styles/hamburgers.min.css';

const HeaderLink = ({ to, children, path, setNavOpen }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(path.includes(to.split('/')[1]));
  }, [path, setIsActive, to]);

  return (
    <li>
      {isActive && <motion.div className="line" layoutId="navLine" />}
      <Link to={to} onClick={() => setNavOpen(false)} activeClassName="active">
        {children}
      </Link>
    </li>
  );
};

const getPathFromUri = (uri) => {
  const path = uri.split('/');
  path.shift();
  return path;
};

export default function Nav({ location, isSmallScreen }) {
  const [navOpen, setNavOpen] = useState(!isSmallScreen);
  const path = getPathFromUri(location.pathname);

  useEffect(() => {
    setNavOpen(!isSmallScreen);
  }, [isSmallScreen, setNavOpen]);

  return (
    <>
      {isSmallScreen && (
        <div className={burgerHolder}>
          <button
            className={`hamburger hamburger--slider
  ${navOpen ? 'is-active' : ''}`}
            type="button"
            onClick={() => setNavOpen(!navOpen)}>
            <span className="hamburger-box">
              <span className="hamburger-inner">Toggle Nav</span>
            </span>
          </button>
        </div>
      )}
      <div className={`${navHolder} ${isSmallScreen ? 'smallScreen' : 'notSmallScreen'} ${!isSmallScreen || navOpen ? 'navOpen' : 'navClosed'}`}>
        <nav>
          <ul className="linksHolder">
            <HeaderLink to="/what-we-are" title="What are The Creatives?" setNavOpen={setNavOpen} path={path}>
              What we are
            </HeaderLink>

            <HeaderLink to="/who-we-are/masha" title="Who are The Creatives?" setNavOpen={setNavOpen} path={path}>
              Who we are
            </HeaderLink>

            {/* <HeaderLink to="/press" title="Press reports relevant to The Creatives film collective" setNavOpen={setNavOpen} path={path}>
              Press
            </HeaderLink> */}

            <HeaderLink to="/contact" title="Contact The Creatives" setNavOpen={setNavOpen} path={path}>
              Contact
            </HeaderLink>
          </ul>
        </nav>
      </div>
    </>
  );
}
