import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { layoutHolder } from './Layout.module.scss';
import Nav from './Nav';
import { motion, AnimatePresence } from 'framer-motion';
import '../styles/styles.scss';
import CompanyNav from '../components/CompanyNav';
import FadingColors from '../components/FadingColors';
import { useIsSmallScreen } from '../hooks/useWindowSizes';
import hydrateImages from '../utils/hydrateImages';

const variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      // when: 'afterChildren',
    },
  },
};

export default function Layout({ children, location, pageContext, ...props }) {
  const [isNavigating, setIsNavigating] = useState(true);
  const isSmallScreen = useIsSmallScreen(987);
  const isWhoWeAre = location.pathname.includes('who-we-are') && location.pathname !== '/who-we-are';

  return (
    <div className={layoutHolder}>
      <Header location={location} />
      <Nav location={location} isSmallScreen={isSmallScreen} />
      <div className={`content ${isWhoWeAre ? 'whoWeAre' : ''}`}>
        <AnimatePresence mode="wait">
          <motion.main
            key={location.pathname}
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            onAnimationStart={() => {
              setIsNavigating(true);
            }}
            onAnimationComplete={(definition) => {
              setIsNavigating(false);
              if (definition === 'animate') {
                //get hash of current location
                if (typeof window !== 'undefined') {
                  const hash = location.hash;
                  let element = undefined;
                  //get element with that hash
                  if (hash) element = document.querySelector(hash);

                  //scroll to that element
                  if (element) element.scrollIntoView({ behavior: 'smooth' });
                  else window.scrollTo({ top: 0, behavior: 'smooth' });
                }
              }
              setTimeout(() => hydrateImages(), 100);
            }}>
            {children}
          </motion.main>
        </AnimatePresence>
        <AnimatePresence>
          {isWhoWeAre && (
            <CompanyNav
              key="companyNav"
              location={location}
              pageContext={pageContext}
              isNavigating={isNavigating}
              setIsNavigating={setIsNavigating}
            />
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>{!isWhoWeAre && <FadingColors />}</AnimatePresence>
      <Footer />
    </div>
  );
}
