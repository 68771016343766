import React from 'react';
import { headerHolder } from './Header.module.scss';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

const times = (length, fn) => Array.from({ length }, (_, i) => fn(i));

const headerVariants = {
  initial: {
    opacity: 0,
    y: '29vh',
    scale: 0.68,
  },
  homeHeader: {
    opacity: 1,
    y: '19vh',
    scale: 0.7,
    transition: {
      duration: 0.7,
      type: 'tween',
    },
  },
  smallHeader: {
    opacity: 1,
    y: '-5vh',
    scale: 0.3,
    transition: {
      duration: 0.5,
      type: 'tween',
    },
  },
  whatWeAreHeader: {
    opacity: 1,
    y: '13vh',
    scale: 0.65,
    transition: {
      duration: 0.5,
      type: 'tween',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
      type: 'tween',
      ease: 'anticipate',
    },
  },
};

export default function Header({ location }) {
  const isHome = location.pathname === '/';
  const isWhoWeAre = location.pathname.includes('who-we-are');
  const isWhatWeAre = location.pathname.includes('what-we-are');
  const isPress = location.pathname.includes('press');
  const isContact = location.pathname.includes('contact');
  let headerVariant = 'homeHeader';
  if (isWhoWeAre || isPress || isContact) {
    headerVariant = 'smallHeader';
  }
  if (isWhatWeAre) {
    headerVariant = 'whatWeAreHeader';
  }

  return (
    <motion.header className={headerHolder}>
      <motion.div className="headerInner" variants={headerVariants} initial="initial" animate={headerVariant} exit="exit">
        <Link to="/" title="Home">
          <h1>
            <span className="the">The</span> <span className="creatives">Creatives</span>
          </h1>
          <div className="dots">
            {times(10, (i) => (
              <div className="dot" key={i} />
            ))}
          </div>
        </Link>
      </motion.div>
    </motion.header>
  );
}
