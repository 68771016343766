import { useStaticQuery, graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { fadingColorsHolder } from './FadingColors.module.scss';
import { AnimatePresence, motion } from 'framer-motion';
import useInterval from '../hooks/useInterval';

const fadingVariants = {
  initial: {
    opacity: 0,
    y: '20%',
    scale: 0.8,
  },
  animate: ({ delay, slideLength }) => ({
    opacity: 0.25,
    y: 0,
    scale: 1,
    transition: {
      duration: slideLength / 2,
      delay: delay,
      ease: 'easeInOut',
    },
  }),
  exit: ({ delay, slideLength }) => ({
    opacity: 0,
    y: '-20%',
    scale: 0.6,
    transition: {
      duration: slideLength / 2,
      ease: 'easeInOut',
      delay: delay,
    },
  }),
};

export default function FadingColors() {
  const [evenColor, setEvenColor] = useState(0);
  const [oddColor, setOddColor] = useState(0);
  const slideLength = 10;
  const data = useStaticQuery(graphql`
    query ColoursQuery {
      allWpCompany(sort: { fields: title, order: ASC }) {
        nodes {
          companyInformation {
            lighterColour
            darkerColour
          }
        }
      }
    }
  `);

  const colors = data.allWpCompany.nodes.map((company) => [company.companyInformation.lighterColour, company.companyInformation.darkerColour]).flat();

  //odd members of colors array
  const oddColors = colors.filter((color, index) => index % 2 !== 0);

  //even members of colors array
  const evenColors = colors.filter((color, index) => index % 2 === 0);

  useInterval(
    () => {
      // Your custom logic here
      // console.log((evenColor + 1) % evenColors.length);
      setEvenColor((evenColor) => (evenColor + 1) % evenColors.length);
      setOddColor((oddColor) => (oddColor + 1) % oddColors.length);
    },
    // Delay in milliseconds or null to stop it
    slideLength * 1000
  );

  return (
    <div className={fadingColorsHolder}>
      <AnimatePresence>
        <motion.div
          className="oddColor"
          key={oddColor}
          variants={fadingVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          custom={{ slideLength: slideLength, delay: 0 }}
          style={{ backgroundColor: oddColors[oddColor] }}
        />
        <motion.div
          className="evenColor"
          key={evenColor + 100}
          variants={fadingVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          custom={{ delay: slideLength / 2, slideLength: slideLength }}
          style={{ backgroundColor: evenColors[evenColor] }}
        />
      </AnimatePresence>
    </div>
  );
}
