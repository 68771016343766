import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { footerHolder } from './Footer.module.scss';

export default function Footer() {
  return (
    <footer className={footerHolder}>
      <div className="copyright">
        <p>&copy; {new Date().getFullYear()} The Creatives</p>
      </div>
      <div className="credit">
        <p>
          Site by <a href="mailto:ben@lookupstudios.co.uk">Look Up!</a>
        </p>
      </div>
    </footer>
  );
}
